import React from 'react';
import { ButtonCallToAction } from '../../ButtonCallToAction';
import { generateCardId } from '@/uikit/helper_funcs/generateCardId';
import { hexToRgb } from '@/uikit/helper_funcs/hexToRgb';
import Image from 'next/image';
export type TSTState = {
  title?: string;
  game_name?: string;
  override_color_title?: string;
  description?: string;
  game_description?: string;
  background_image_desktop?: string;
  background_image_mobile?: string;
  background_color?: string;
  override_color_overlay?: string;
  override_color_text?: string;
  link_label?: string;
  override_button_normal_border?: string;
  override_button_normal_background?: string;
  override_button_normal_background_end?: string;
  override_button_normal_color?: string;
  override_button_normal_should_use_border?: null | boolean;
  override_button_hover_border?: string;
  override_button_hover_background?: string;
  override_button_hover_background_end?: string;
  override_button_hover_color?: string;
  override_button_hover_should_use_border?: null | boolean;
  override_button_active_border?: string;
  override_button_active_background?: string;
  override_button_active_background_end?: string;
  override_button_active_color?: string;
  override_button_active_should_use_border?: null | boolean;
};
export const PromoCard = (props: TSTState) => {
  const state = props || {};
  const id = generateCardId('ark-ui-block-card-promo');
  const title = state?.title || state?.game_name || '';
  const description = state?.description || state?.game_description || '';
  const backgroundImageDesktop = state?.background_image_desktop || state?.background_image_mobile || '';
  //const backgroundImageMobile = state?.background_image_mobile || state?.background_image_desktop || '';

  const color = state?.background_color || state?.override_color_overlay || '#000000';
  const rgbaColor = hexToRgb(color);
  const overlayColor = `linear-gradient(90deg, ${color} 47.19%, ${rgbaColor}, 0.60) 73.6%, ${rgbaColor}, 0.00) 84.16%)`;
  const tabletOverlay = `linear-gradient(90deg, ${color} 47.19%, ${rgbaColor}, 0.25) 73.6%, ${rgbaColor}, 0.00) 84.16%)`;
  const mobileOverlay = `${rgbaColor}, 0.7)`;
  const linkLabel = state?.link_label || 'Play Today';

  // Call-to-action button (using the provided markup generator)
  const button = {
    text: linkLabel,
    addClass: 'link',
    tabIndex: -1,
    ...{
      override_button_normal_color: state?.override_button_normal_color,
      override_button_normal_background: state?.override_button_normal_background,
      override_button_normal_background_end: state?.override_button_normal_background_end,
      override_button_normal_border: state?.override_button_normal_border,
      override_button_normal_should_use_border: state?.override_button_normal_should_use_border !== false,
      override_button_hover_color: state?.override_button_hover_color,
      override_button_hover_background: state?.override_button_hover_background,
      override_button_hover_background_end: state?.override_button_hover_background_end,
      override_button_hover_border: state?.override_button_hover_border,
      override_button_hover_should_use_border: state?.override_button_hover_should_use_border !== false,
      override_button_active_color: state?.override_button_active_color,
      override_button_active_background: state?.override_button_active_background,
      override_button_active_background_end: state?.override_button_active_background_end,
      override_button_active_border: state?.override_button_active_border,
      override_button_active_should_use_border: state?.override_button_active_should_use_border !== false
    }
  };
  const dynamicStyles = `
    #${id}:after {
      pointer-events: none;
      background: ${mobileOverlay};
      z-index: 50;
    }
    #${id} {
      backdrop-filter: blur(2.5px);
    }
    @media (min-width: 568px) {
      #${id} {
        backdrop-filter: none;
      }
      #${id}:after {
        background: ${tabletOverlay};
      }
    }
    @media (min-width: 1025px) {
      #${id}:after {
        background: ${overlayColor};
      }
    }
  `;
  return <>
			<style dangerouslySetInnerHTML={{
      __html: dynamicStyles
    }} />
			<div id={id} className='card' tabIndex={-1}>
				<Image src={backgroundImageDesktop} alt={title} className='card_image' loading='lazy' layout='responsive' width={1080} height={400} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
				<h2 className='card_title'>
					<span id={`promo_card_title_${title}`} style={{
          color: state?.override_color_title || ''
        }}>
						{title}
					</span>
				</h2>
				<p className='card_description'>
					<span id={`promo_card_description_${title}`} style={{
          color: state?.override_color_text || ''
        }}>
						{description}
					</span>
				</p>
				<ButtonCallToAction {...button} data-sentry-element="ButtonCallToAction" data-sentry-source-file="index.tsx" />
			</div>
		</>;
};