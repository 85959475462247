'use client';

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
// @ts-ignore
import { Splide, SplideProps, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import { useUnit } from 'effector-react';
import { $arenaDataStore } from '@/app.model';
import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import { useDeviceDetector } from '@/libs/utils/userAgentContext';
import { LocalStorageService } from '@/libs/utils/local-storage';
import { AnalyticsContext } from '@/components/Layout';
import { ETabIndexesOrderingLevels } from '@/types/types';
import { LocalizedLink } from '@/components/Link';
import { UIKIT_CONSTS } from '@/components/UiKit/UiKitConnector';
import { overrideSlideAttributes, TCarouselRecentlyPlayed } from './model';
import { CardPlaceholder } from '@/components/CardPlaceholder';
import { UiKitRenderStatic } from '@/components/UiKit/UiKitRender';
import { renderMarkup } from '../../uikit_to_delete/components/ark-ui-block-card-standard/_index';
import ButtonPrev from '@/features/carousel/button-prev.svg';
import ButtonNext from '@/features/carousel/button-next.svg';
import classNames from 'classnames';

// Recently Played carousel settings:
const SLIDER_OPTIONS: SplideProps['options'] = {
  type: 'slide',
  pauseOnHover: true,
  pauseOnFocus: true,
  focus: 0,
  pagination: true,
  // Enable pagination
  omitEnd: true,
  autoWidth: true,
  lazyLoad: true,
  slideFocus: true,
  focusableNodes: ''
};

// Carousel component
export const Slider = (props: TCarouselRecentlyPlayed) => {
  const arenaData = useUnit($arenaDataStore);
  const recentlyPlayedGames = LocalStorageService.getItem('recentlyPlayed');
  const recentlyPlayedGamesList = recentlyPlayedGames ? JSON.parse(recentlyPlayedGames) : [];
  const splideRef = useRef<Splide>(null);
  const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);
  const [isArrowClicked, setIsArrowClicked] = useState(false);
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);
  const [isRightArrowVisible, setIsRightArrowVisible] = useState(true);
  const carouselId = props?.analyticId;
  const cardRefs = useRef<(HTMLAnchorElement | null)[]>([]);
  const {
    isMobile
  } = useDeviceDetector();
  const {
    AITracks
  } = useContext(AnalyticsContext);
  function sendAnalytics(eventName: string) {
    AITracks.genericTrack({
      event: eventName,
      isNonInteraction: false,
      eventAction: AnalyticsEventAction.CLICK
    });
  }
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (['ArrowLeft', 'ArrowRight', 'Enter', 'Tab'].includes(event.key)) {
        setIsKeyboardNavigation(true);
      }
    };
    const handleMouseDown = () => {
      setIsKeyboardNavigation(false);
    };
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);
  useEffect(() => {
    if (!splideRef?.current?.splideRef?.current) {
      return;
    }
    const splide = splideRef.current.splide;

    // Update arrow visibility based on the current slide index
    splide.on('moved', (index: number) => {
      const isEnd = index === splide?.Components?.Controller?.getEnd?.();
      const isStart = index === 0;

      // Set arrow visibility based on the current position
      setIsLeftArrowVisible(!isStart);
      setIsRightArrowVisible(!isEnd);
    });
  }, [splideRef]);
  const renderSlide = (cardIndex: number) => {
    if (recentlyPlayedGamesList && recentlyPlayedGamesList[cardIndex]) {
      // Render game slides if they exist in the list
      const overridenSlide = overrideSlideAttributes(recentlyPlayedGamesList[cardIndex], arenaData, isMobile(), AITracks, props?.cardsType, carouselId);
      if (!overridenSlide) {
        return null;
      }
      const {
        attributes,
        handleClick
      } = overridenSlide;
      const gameAliasOrSlug = recentlyPlayedGamesList[cardIndex]?.meta?.alias || recentlyPlayedGamesList[cardIndex]?.slug;
      const labelTitle = recentlyPlayedGamesList[cardIndex]?.name ? `Play ${recentlyPlayedGamesList[cardIndex]?.name}` : 'Clickable card';
      return <SplideSlide key={`game-${cardIndex}`} className='splide__slide' aria-label={labelTitle} data-index={cardIndex}>
					<LocalizedLink prefetch='default' tabIndex={ETabIndexesOrderingLevels.NOINTERACTION} ref={el => {
          cardRefs.current[cardIndex] = el;
        }} key={`/games/${recentlyPlayedGamesList[cardIndex]?.slug}`} href={`/games/${gameAliasOrSlug}`} className={UIKIT_CONSTS.nextjsLinkWrapperClassname + ' recently-played_link'} target={'_self'} onClick={handleClick} aria-label='Clickable recently played game card'>
						<UiKitRenderStatic innerHTML={renderMarkup(attributes)} tagName={'ark-ui-block-card-standard'} />
					</LocalizedLink>
				</SplideSlide>;
    } else {
      // Render placeholders if no more games are left
      return <SplideSlide key={`placeholder-${cardIndex}`} className='splide__slide'>
					<CardPlaceholder text={props?.placeholderText} size={props?.cardsType} />
				</SplideSlide>;
    }
  };

  // Set render of slides
  const slides = useMemo(() => {
    const totalSlides = 10;

    // Create an array of length `totalSlides`, where some items come from games and others are placeholders
    const allSlides = Array.from({
      length: totalSlides
    }, (_, index) => renderSlide(index));
    return allSlides;
  }, [recentlyPlayedGamesList, sendAnalytics]);

  // Handle left and right arrow clicks with focus update logic for keyboard users
  const handleArrowClick = async (isLeft: boolean) => {
    sendAnalytics(isLeft ? 'sliderLeft' : 'sliderRight');
    const splide = splideRef.current?.splide;
    setIsArrowClicked(true);
    if (splide) {
      if (isLeft) {
        splide.go('<');
      } else {
        splide.go('>');
      }
    }
  };

  // Handle keyboard navigation with Enter/Space key press
  const handleArrowKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>, isLeft: boolean) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      e.stopPropagation(); // Stop propagation to prevent the event from firing multiple times
      handleArrowClick(isLeft);
    }
  };
  const handleCardClick = (index: number) => {
    cardRefs.current[index]?.click(); // Programmatically trigger the Link's click
  };
  const handleTrackEvent = (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;

    // To avoid double click one for li and another for the a tag, we just need the li since the click is on the Link ref
    if (target.tagName.toLowerCase() === 'a') {
      return;
    }
    const slideElement = target.closest('.splide__slide') as HTMLElement;
    if (!slideElement) return;
    event.stopPropagation();
    const index = slideElement.dataset.index; // Get the slide index from `data-index`
    if (index === undefined) return;
    const slideIndex = Number(index);
    if (event.type === 'click') {
      handleCardClick(slideIndex);
    } else if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Enter') {
      event.preventDefault();
      handleCardClick(slideIndex);
    }
  };
  return <Splide options={SLIDER_OPTIONS} className={'carousel__slider recently-played-carousel'} hasTrack={false} role='navigation' ref={splideRef} data-sentry-element="Splide" data-sentry-component="Slider" data-sentry-source-file="Slider.tsx">
			<div className='splide__arrows'>
				<button className={classNames('splide__arrow splide__arrow--prev', {
        ['splide__arrow--visible']: isLeftArrowVisible,
        ['__splide-arrow-clicked']: isArrowClicked
      })} aria-label='Go back to previous slides' onClick={() => handleArrowClick(true)} onKeyDown={e => handleArrowKeyDown(e, true)} onMouseLeave={ev => ev.currentTarget.blur()} tabIndex={ETabIndexesOrderingLevels.AUTO}>
					<ButtonPrev data-sentry-element="ButtonPrev" data-sentry-source-file="Slider.tsx" />
				</button>
			</div>
			<SplideTrack tabIndex={ETabIndexesOrderingLevels.NOINTERACTION} onClick={handleTrackEvent} onKeyDown={handleTrackEvent} data-sentry-element="SplideTrack" data-sentry-source-file="Slider.tsx">
				{slides}
			</SplideTrack>
			<div className='splide__arrows'>
				<button className={classNames('splide__arrow splide__arrow--next', {
        ['splide__arrow--visible']: isRightArrowVisible,
        ['__splide-arrow-clicked']: isArrowClicked
      })} aria-label='Move to next slides' onClick={() => handleArrowClick(false)} onKeyDown={e => handleArrowKeyDown(e, false)} onMouseLeave={ev => ev.currentTarget.blur()} tabIndex={ETabIndexesOrderingLevels.AUTO}>
					<ButtonNext data-sentry-element="ButtonNext" data-sentry-source-file="Slider.tsx" />
				</button>
			</div>
		</Splide>;
};